@import "colors";

.ButtonGroup {
  display: flex;

  > div,
  > button,
  > a {
    margin-right: 15px;
  }

  :last-child,
  :last-of-type {
    margin-right: 0;
  }

  &.right {
    justify-content: flex-end;

    > div,
    > button,
    > a {
      margin-right: 0;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.split {
    justify-content: space-between;

    > div {
      display: flex;

      &:first-child {
        > div,
        > button,
        > a {
          margin-right: 15px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
      &:last-child {
        > div,
        > button,
        > a {
          margin-right: 0;
          margin-left: 15px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  &.centered {
    justify-content: center;
  }
}

@include phone {
  .ButtonGroup {
    flex-direction: column;

    > div,
    > button,
    > a {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 15px;
    }

    &.noInsertedMargin {
      > div,
      > button,
      > a {
        margin-bottom: 0;
      }
    }

    &.right {
      > div,
      > button,
      > a {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }
}

.StyledButton {
  display: inline-flex;
  justify-content: center;
  position: relative;
  background: transparent;
  color: white !important;
  outline: none;
  border: 1px solid $paleBlue;
  border-radius: 6px;
  padding: 8px 25px;
  min-width: 120px;
  font-size: 14px;
  font-weight: 600;
  font-family: "OpenSans", "Roboto", sans-serif;
  cursor: pointer;
  line-height: normal;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.25);

    * {
      color: white !important;
    }
  }

  &.clearWhiteEdge {
    border-color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.25);
      border-color: white;
    }
  }

  &.pink {
    background: $pink;
    border-color: $pink;

    &:hover {
      background: darken($color: $pink, $amount: 4%);
      border-color: darken($color: $pink, $amount: 4%);
    }
  }

  &.disabled {
    background: $linkBlue;
    color: $visitedLinkBlue;
    cursor: default;
    pointer-events: none;

    > div {
      &:after,
      &:before {
        background: transparent !important;
      }
    }
  }

  /*
  &.dark {
    background: $darkblue;
  }
  &.black {
    background: $offblack;
    color: $offwhite;
  }
  &.blackblue {
    background: $offwhite;
    color: $blackblue;

    &.twoPart > div > div:last-of-type {
      background: white;
      color: $blackblue;
    }
  }
  &.lightblue {
    background: white;
    color: $blackblue;

    &.twoPart > div > div:last-of-type {
      background: $offwhite;
      color: $blackblue;
    }
  }*/

  &.twoPart {
    padding: 0;
    border: none;

    > div {
      display: flex;
      padding: 0;
      flex: 1;

      > div:first-of-type {
        padding: 8px 8px 8px 12px;
        flex: 1;
      }
      > div:last-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        min-width: 75px;
        border-left: 1px solid white;
      }
    }

    &.lightAddOn {
      > div {
        > div:last-of-type {
          background: white;
          color: $visitedLinkBlue;
        }
      }
    }
  }

  &.big {
    font-size: 20px;
    padding: 10px 15px;

    &.twoPart {
      padding: 0;
      > div > div:first-of-type {
        padding: 10px 10px 10px 15px;
      }
      > div > div:last-of-type {
        background: $visitedLinkBlue;
        padding: 10px 15px 10px 10px;
      }
    }
  }

  /*@include desktop {
    > div,
    > button {
      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        left: 10px;
        right: 10px;
        height: 0;
        background: white;
        transition: height 0.3s;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
    &:hover,
    &:active {
      > div,
      > button {
        &:before,
        &:after {
          height: 5px;
        }
      }
    }

    &.big {
      &:hover,
      &:active {
        > div,
        > button {
          &:before,
          &:after {
            height: 7px;
          }
        }
      }
    }
  }

  &.lightblue {
    @include desktop {
      > div,
      > button {
        &:before,
        &:after {
          background: $paleblue;
        }
      }
    }
  }
  &.yellowBacked {
    @include desktop {
      > div,
      > button {
        &:before,
        &:after {
          background: $lightyellow;
        }
      }
    }
  }
  &.paleyellowBacked {
    @include desktop {
      > div,
      > button {
        &:before,
        &:after {
          background: $paleyellow;
        }
      }
    }
  }
  &.offwhiteBacked {
    @include desktop {
      > div,
      > button {
        &:before,
        &:after {
          background: $offwhite;
        }
      }
    }
  }
  &.blackblue {
    @include desktop {
      > div,
      > button {
        &:before,
        &:after {
          background: $blackblue;
        }
      }
    }
  }

  &.sideSqueezed {
    @include desktop {
      > div,
      > button {
        &:before,
        &:after {
          top: 8px;
          bottom: 8px;
          width: 0;
          height: auto;
          transition: width 0.3s;
        }

        &:before {
          left: 0;
          right: auto;
        }
        &:after {
          right: 0;
          left: auto;
        }
      }

      &:hover,
      &:active {
        > div,
        > button {
          &:before,
          &:after {
            width: 8px;
          }
        }
      }
    }
  }*/
}
