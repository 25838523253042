@import "colors";

.HomeTalkPromo {
  width: 100%;

  &.partOfSeries {
    padding-left: 115px;

    .detailsBody .speakersArea .individualSpeaker .speakerImage {
      display: none;
    }

    @include phone {
      padding-left: 30px;

      .speakersArea .individualSpeaker .buttonRow {
        margin-left: -15px;
      }
    }
  }

  .detailsBody {
    display: flex;
    width: 100%;

    .speakersArea {
      display: flex;
      align-items: center;

      .individualSpeaker {
        display: flex;

        .speakerImage {
          width: 225px;
          height: 250px;
          border-radius: 45px;
          overflow: hidden;
          margin-right: 20px;

          > div {
            height: 100%;
          }

          img {
            width: 100%;

            &.heightBased {
              width: auto;
              height: 100%;
            }
          }

          &.small {
            img {
            }
          }

          @include phone {
            display: none;
          }
        }
      }
    }
    .flex1 {
      flex: 1;
    }

    .headerInfo {
      display: flex;
      justify-content: space-between;

      @include notDesktop {
        flex-direction: column;
      }
    }

    .aboutArea {
      .talkTitle {
        display: flex;
        font-weight: 600;
        margin: 30px 0 10px;
        font-size: 22px;

        .speakerName {
          margin-left: 5px;

          > div {
            margin-bottom: 5px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        span {
          margin-left: 6px;
          font-weight: 400;
          font-style: italic;
        }

        @include notDesktop {
          flex-direction: column;

          .speakerName {
            margin-left: 0;
          }

          span {
            font-weight: 400;
            margin: 5px 0;
          }

          margin-bottom: 25px;
        }
      }
      .talkSubtitle {
        font-weight: 500;
        font-size: 20px;
      }
      .aboutTheTalk {
        margin-top: 20px;
        font-size: 16px;
      }
    }

    .buttonRow {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 40px;

      :last-child {
        margin-right: 0;
      }

      @include phone {
        > div,
        a {
          max-width: 130px;
          min-width: 0;
          text-align: center;
          margin-right: 10px;
        }
      }
    }
  }

  @include phone {
    .DateTimeDisplayer .times {
      display: none;
    }
  }
}
