@import "colors";
@import "section";

.ConnectingLanding {
}

.About {
  @extend %Section;

  .title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 35px;
  }
}
