@use "sass:color";
@import "colors";

.schedule {
  width: 100%;
  padding: 15px 0 0;
  margin-bottom: 60px;

  .scheduleDay {
    margin-bottom: 60px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .scheduleDayDate {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 15px;
      padding: 0 15px;

      span {
        font-size: 14px;
        position: absolute;
        top: -4px;
      }
    }
  }

  .scheduleTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      margin-left: 28px;
      color: $colorfulText;
    }
  }

  .scheduleInstance {
    display: flex;
    min-height: 42px;
    line-height: 42px;
    padding: 0 15px;
    font-size: 18px;

    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.1);
    }
    &.break {
      background: $darkBlue;
      color: lighten($paleBlue, 10%);
      min-height: 36px;
      line-height: 36px;
      font-size: 14px;

      .scheduleInstanceTime {
        color: white;
        font-size: 18px;
      }
    }

    .scheduleInstanceTime {
      max-width: 125px;
      min-width: 125px;
    }
    .scheduleInstanceInfo {
      flex: 1;
    }
  }
}
