$pink: #f28c8f;

$paleBlue: #6ea2b4;
$darkBlue: #073449;

$linkBlue: #4da1c5;
$visitedLinkBlue: #0b1c3a;
$buttonBlue: #337895;

$colorfulText: #396b7f;
$greyText: #181818;
