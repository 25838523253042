.DateTimeDisplayer {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 22px;
  cursor: default;

  * {
    display: flex;
    align-items: center;
  }

  .times {
    margin-left: 12px;
    font-weight: 400;
  }
  .date {
    font-weight: 500;
  }

  &.bubble {
    border-radius: 51%;
  }

  &.fullWidth {
    width: 100%;
    text-align: center;
  }

  &.deemphasize {
    background: $linkBlue;
    color: $greyText;
  }

  .secondDate {
    padding-left: 12px;

    .date {
      margin-left: 8px;
    }
  }

  @include notDesktop {
    font-size: 18px;
    padding: 0 0 10px;

    .secondDate {
      display: none;
    }
  }

  @include phone {
    padding: 0 0 5px;
  }
}
