@import "colors";

.previousTalks {
  background: rgba(255, 255, 255, 0.35);
  color: white;
  margin: 120px 0;
  padding: 60px 0 40px;
  width: 100%;

  .innerWidth {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 34px;
      font-weight: 500;
      margin-bottom: 30px;

      input {
        padding: 4px 28px 4px 8px;
        box-shadow: none;
        outline: none;
        background: rgba(255, 255, 255, 0.5);
        border: 1px white solid;

        &:hover,
        &:focus,
        &:active {
          border: 1px $darkBlue solid;
        }
      }

      img.searchIcon {
        position: absolute;
        top: 16px;
        right: 7px;
        width: 16px;
        height: 16px;
      }

      @include phone {
        display: block;
        padding-left: 30px;

        input {
          width: calc(100% - 30px);
        }

        img.searchIcon {
          top: 70px;
          right: 40px;
        }
      }
    }

    .talksList {
      max-height: 650px;
      overflow-y: auto;
      padding-right: 25px;

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $pink;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: white;
      }
      scrollbar-width: thin;
      scrollbar-color: $pink white;

      .oneTalk {
        padding-left: 100px;
        margin-bottom: 50px;

        @include phone {
          padding-left: 30px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.partOfSeries {
          padding-left: 115px;

          .detailsBody .speakersArea .individualSpeaker .speakerImage {
            display: none;
          }
        }

        .detailsBody {
          display: flex;
          width: 100%;

          .speakersArea {
            display: flex;
            align-items: center;

            .individualSpeaker {
              display: flex;
              width: 180px;
              margin-right: 20px;

              .speakerImage {
                width: 160px;
                height: 175px;
                border-radius: 45px;
                overflow: hidden;

                > div {
                  height: 100%;
                }

                img {
                  width: 100%;

                  &.heightBased {
                    width: auto;
                    height: 100%;
                  }
                }

                &.small {
                  img {
                  }
                }

                @include phone {
                  display: none;
                }
              }
              .speakerName {
                margin-top: 8px;
                font-weight: 600;
                margin-bottom: 10px;
              }
            }

            @include phone {
              display: none;
            }
          }

          .flex1 {
            flex: 1;
          }

          .headerInfo {
            display: flex;
            justify-content: space-between;

            @include notDesktop {
              flex-direction: column;
            }
          }

          .aboutArea {
            color: $darkBlue;

            .talkTitle {
              display: flex;
              font-weight: 600;
              margin: 10px 0 8px;
              font-size: 22px;

              span {
                margin-left: 6px;
              }

              .authorOnPhone {
                display: none;
                font-weight: 400;

                > div {
                  font-weight: 600;

                  span {
                    font-weight: 400;
                  }

                  &:last-of-type span {
                    display: none;
                  }
                }
              }

              @include notDesktop {
                flex-direction: column;

                span {
                  font-weight: 400;
                  margin: 5px 0;
                }

                margin-bottom: 25px;
              }

              @include phone {
                .authorOnPhone {
                  display: block;
                }
              }
            }
            .talkSubtitle {
              font-weight: 500;
              font-size: 20px;
            }
            .aboutTheTalk {
              margin-top: 20px;
              font-size: 16px;
            }
          }

          .buttonRow {
            width: 100%;
            margin-top: 15px;

            @include phone {
              > div,
              a {
                width: 100%;
                min-width: 0;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
