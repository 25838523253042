@import "colors";
@import "section";
@import "media";

.DetailsPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttonRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .detailsBody {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 20px;
    max-width: 1200px;
    width: 100%;

    @include phone {
      grid-template-columns: 1fr;
    }

    .headerInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include notDesktop {
        flex-direction: column;
      }
    }

    .speakersArea {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: white;
      font-size: 14px;

      .individualSpeaker {
        padding-top: 20px;
        border-top: 1px solid white;
        margin-top: 20px;

        &:first-of-type {
          padding-top: 0;
          border-top: none;
          margin-top: 0;
        }

        .speakerImage {
          position: relative;
          width: 140px;
          height: 160px;
          border-radius: 45px;
          overflow: hidden;

          > div {
            height: 100%;
          }

          img {
            max-width: 100%;

            &.heightBased {
              height: 100%;
              max-width: none;
            }
          }
        }

        .speakerName {
          font-weight: 900;
          margin: 10px 0 5px;
        }
      }

      @include phone {
        align-items: center;

        .individualSpeaker {
          display: flex;
          flex-direction: column;
          align-items: center;

          .speakerImage {
            width: 200px;
            height: 225px;
          }
        }
      }
    }

    .aboutArea {
      @extend %Section;

      .talkTitle {
        position: relative;
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 35px;
        color: $paleBlue;

        &:after {
          position: absolute;
          bottom: -18px;
          left: 0;
          height: 4px;
          width: 150px;
          border-radius: 4px;
          background: $pink;
          content: "";
        }
      }

      .talkSubtitle {
        margin-bottom: 15px;
      }

      .intendedInstructionLevel {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        padding-bottom: 25px;
        border-bottom: 3px solid $paleBlue;
        margin-bottom: 25px;

        .level {
          font-weight: 600;
          margin-right: 8px;
        }
      }

      .aboutTheTalk,
      .educationalObjectives,
      .aboutTheSpeaker,
      .interestConflicts {
        .title {
          font-size: 20px;
          font-weight: 900;
          color: $paleBlue;
          margin-bottom: 25px;
          text-transform: uppercase;
        }

        padding-bottom: 25px;
      }

      .aboutTheTalk,
      .educationalObjectives,
      .interestConflicts {
        border-bottom: 3px solid $paleBlue;
        margin-bottom: 25px;

        .objective,
        .conflict {
          position: relative;
          padding-left: 14px;
          margin-top: 6px;

          &:before {
            position: absolute;
            content: "";
            left: 4px;
            top: 9px;
            width: 4px;
            height: 4px;
            background: $paleBlue;
          }
        }
      }

      .interestConflicts {
        border-bottom: none;
        border-top: 3px solid $paleBlue;
        margin-bottom: 0;
        padding-top: 25px;
      }

      .aboutTheTalk {
        .sessionList {
          font-weight: 500;
          color: $darkBlue;

          span {
            font-weight: 600;
            color: $paleBlue;
          }
        }

        .objective,
        .session {
          padding-left: 30px;
          margin-top: 4px;

          &:before {
            left: 20px;
          }
        }
        .session {
          margin-top: 10px;

          &:first-of-type {
            margin-top: 4px;
          }
        }
      }

      .aboutTheSpeaker {
        .individualSpeaker {
          margin-top: 25px;
          border-top: 1px solid $darkBlue;
          padding-top: 25px;

          &:nth-of-type(2) {
            margin-top: 0;
            border-top: none;
            padding-top: 0;
          }
        }
      }
    }

    .scheduleArea {
      @extend %Section;
      padding: 35px 10px !important;

      .talkTitle {
        position: relative;
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 35px;
        color: $paleBlue;

        &:after {
          position: absolute;
          bottom: -18px;
          left: 0;
          height: 4px;
          width: 150px;
          border-radius: 4px;
          background: $pink;
          content: "";
        }
      }

      .talkSubtitle {
        margin-bottom: 15px;
      }
    }
  }
}
