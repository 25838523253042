@import "catalog";
@import "common";
@import "home";
@import "mainmenu";
@import "button";
@import "talks";
@import "modal";
@import "video";
@import "schedule";
@import "connecting";
@import "detailspage";
@import "datetimedisplayer";
@import "policies";
@import "hometalkpromo";
@import "previoustalks";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  &.modal-open {
    overflow: hidden;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.TalksGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: "OpenSans", "Roboto", sans-serif;

  background: linear-gradient(
    #073449 0 20px,
    #337d9c 250px,
    #305f72 50%,
    #4da1c5 75%,
    #765c6a calc(100% - 250px) 100%
  );

  &.home {
    background: linear-gradient(
      #073449 0 140px,
      #337d9c 40%,
      #305f72 60%,
      //#4da1c5 80%,
      #765c6a calc(100% - 250px) 100%
    );
  }

  &.about {
    background: linear-gradient(
      #073449 0 140px,
      #337d9c 40%,
      #765c6a calc(100% - 250px) 100%
    );
  }
}

.MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 400px;
  max-width: 1350px;
  width: 100%;
  color: $greyText;

  .content {
    flex: 1;
    padding: 35px 25px;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }

  .areaTitle {
    font-size: 20px;
    font-weight: 600;
  }
}

.MainFooter {
  margin-top: 80px;
  min-height: 240px;
  font-size: 13px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 45px 60px 15px;

  a {
    font-weight: 800;
    color: white;

    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .mission {
    line-height: 18px;
    color: white;
    overflow-y: auto;
    margin-bottom: 10px;

    > div {
      margin-bottom: 8px;
    }

    .missionObjective {
      position: relative;
      padding-left: 14px;
      margin-top: 6px;
      line-height: 20px;

      &:before {
        position: absolute;
        content: "";
        left: 4px;
        top: 7px;
        width: 4px;
        height: 4px;
        background: white;
      }
    }

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: $linkBlue;
    }
    scrollbar-width: thin;
    scrollbar-color: $linkBlue $buttonBlue;
  }

  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-top: 40px;

    .title {
      color: white;
      font-weight: 300;
      margin-bottom: 8px;
    }

    .item {
      margin-bottom: 5px;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }

  .apa {
    font-size: 12px;
    max-width: 600px;
    display: flex;
    align-items: center;

    @include phone {
      max-width: 350px;
      padding-left: 10px;
    }
  }
}
