@import "colors";

.VideoWrapper {
  margin-bottom: 120px;

  .videoTitle {
    padding: 15px 15px 12px;
    color: white;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .videoAnchor {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    background: white;
    overflow: hidden;
    border-radius: 30px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.dark {
    .videoTitle {
      color: $darkBlue;
    }
  }
}
