$tablet-width: 768px;
$desktop-width: 1035px;

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin notPhone {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin notDesktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.phoneOnly {
  @include phone {
    display: block;
  }
  @include notPhone {
    display: none;
  }
}
.notOnPhone {
  @include phone {
    display: none;
  }
  @include notPhone {
    display: block;
  }
}

.desktopOnly {
  @include notDesktop() {
    display: none;
  }
  @include desktop() {
    display: block;
  }
}
.notOnDesktop {
  @include notDesktop() {
    display: block;
  }
  @include desktop() {
    display: none;
  }
}
