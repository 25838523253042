@import "media";

.MainMenu {
  position: relative;
  color: white;
  margin-bottom: 70px;
  padding: 25px 25px 0;
  width: 100%;
  max-width: 1350px;
  box-sizing: border-box;

  .topLine {
    display: flex;
    align-items: center;
  }

  .item {
    font-size: 24px;
  }

  a.logoHolder {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    margin-right: 8px;
    z-index: 10000;

    img {
      height: 30px;
    }

    &.home {
      height: 150px;

      img {
        height: 100%;
      }
    }
  }

  .InternalLink {
    font-size: 16px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: "Roboto";

    > div {
      text-align: center;
    }

    .name {
      font-size: 32px;
      font-weight: 200;
      margin-bottom: 25px;
    }

    .break {
      display: inline-block;
      height: 3px;
      width: 150px;
      border-radius: 3px;
      background: $pink;
      margin-bottom: 30px;
    }

    .slogan {
      font-size: 60px;
      font-weight: 400;
    }
  }

  .titleHolder {
    position: relative;

    a.mobileTitle {
      display: none;
      position: absolute;
      background: black;
      padding: 4px 11px;
      left: 64px;
      top: 8px;
      right: 85px;
      z-index: 2;
      font-size: 20px;
      border-radius: 26px;
      min-height: 53px;
      height: 53px;
    }

    a.title {
      display: flex;
      align-items: center;
      background: transparent;
      font-size: 28px;
      font-weight: 600;
      padding: 0 20px;
      height: 70px;
      min-height: 70px;
      transition: background 1.5s, color 0.75s ease-in;

      @include desktop {
        &:after {
          bottom: 10px !important;
          background: white;
          left: 20px;
          width: calc(100% - 40px) !important;
          opacity: 0;
          transition: opacity 0.75s, color 0.75s ease-in !important;
        }
        &:hover,
        &:active {
          background: white;
          color: black;

          &:after {
            opacity: 1;
            background: black;
          }
        }
      }
    }

    a.title,
    a.mobileTitle {
      title {
        display: block;
      }
    }
  }

  .leftMenu,
  .rightMenu {
    flex: 1;
    display: flex;
    padding: 0 20px;
  }
  .leftMenu {
    .item {
      margin-right: 20px;
    }
  }
  .rightMenu {
    justify-content: flex-end;

    .item {
      margin-left: 20px;
    }
  }

  /*.byline {
    position: absolute;
    bottom: -56px;
    left: 24px;
    width: 625px;
    font-size: 44px;
    font-weight: 600;
    font-style: italic;
    color: $strongblue;
    z-index: 1;
  }*/

  @include notDesktop {
    .rightMenu {
      > .item {
        margin-left: 18px;
        font-size: 20px;
      }
    }
  }

  @include tablet {
    a.title title {
      font-size: 19px;
    }
    .byline {
      font-size: 28px;
      max-width: 425px;
    }
  }

  @include phone {
    position: relative;
    margin-bottom: 50px;

    .topLine {
      margin-bottom: 40px;

      a.logoHolder.home {
        height: 100px;
      }
    }

    .leftMenu {
      display: none;
    }

    .titleHolder {
      position: static;

      a.title {
        display: none;
      }
      a.mobileTitle {
        /*display: flex;*/
        align-items: center;
      }

      .byline {
        bottom: -50px;
        left: 150px;
        width: auto;
        font-size: 20px;
      }
    }

    .rightMenu {
      padding: 0;

      > .item {
        margin-left: 0;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .mobileCut {
        display: none;
      }
    }
  }
}
