@import "colors";
@import "media";

.HomePage {
  margin-top: 20px;
  color: white;

  .comingSoon {
    .title {
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    .comingTalks {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 15px;
      padding: 35px 60px;
      margin-bottom: 15px;

      .break {
        width: 100%;
        height: 3px;
        background: $paleBlue;
        margin: 35px 0;

        &.series {
          width: calc(100% - 240px);
          margin-left: 240px;
        }
      }

      @include phone {
        padding: 35px 45px;

        .break.series {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
