@import "colors";
@import "media";

%Section {
  background: rgba(255, 255, 255, 0.87);
  padding: 35px 60px;
  border-radius: 15px;
  /*  position: relative;
  margin: 0 -20px 20px;
  font-family: "Lato", sans-serif;

  .heading {
    padding: 15px 15px 12px;
    background: $paleyellow;
    color: black;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;

    &.moments {
      color: $palered;
      background: $offwhite;
    }

    &.withHeaderImage {
      padding-right: 155px;
    }
  }

  .body {
    position: relative;
    padding: 15px;
    background: $paleyellow;

    .subheading {
      margin: -15px -15px 15px;
      padding: 8px 15px;
      background: white;
      color: #888;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    .imageBuffer {
      display: none;
      float: right;
      width: 200px;
      height: 75px;
    }

    &.moments {
      border: 1px solid $paleyellow;
      border-top: none;
      background: white;
    }

    &.withHeaderImage {
      .imageBuffer {
        display: block;
      }
    }
  }

  .headerImageHolder {
    position: absolute;
    top: -45px;
    right: 40px;
    width: 150px;
    height: 185px;
    display: flex;
    justify-content: center;
    border-radius: 40px;
    overflow: hidden;

    img,
    svg {
      height: 100%;
    }
  }

  &.withRightArea {
    display: grid;
    grid-template-columns: 60% 40%;

    .heading,
    .body {
      padding-left: 20px;
    }

    .heading {
      grid-column: 1 / span 2;
      font-size: 30px;
      line-height: 34px;
      font-weight: 600;
    }

    .subheading {
      font-size: 22px;
    }

    .rightArea {
      background: $paleyellow;
      border-left: 40px solid white;
      &:before {
      }
    }
  }

  &.whiteRightArea.withRightArea {
    .rightArea {
      background: white;
    }
  }

  &.emphasis {
    .heading,
    .body,
    .moments {
      background: $lightyellow;
    }
  }

  &.lightBlueEmphasis {
    .heading,
    .body,
    .moments {
      background: $paleblue;

      .DateTimeDisplayer {
        background: white;
        color: $blackblue;

        @include phone {
          border-top: 2px solid $paleblue;
        }
      }
    }
  }

  &.darkBody {
    .body {
      padding: 15px 0;
      background: $darkblue;

      > div {
        background: $offwhite;
      }
    }
  }

  &.training {
    .heading,
    .body,
    .moments {
      background: $blackblue;
      color: white;
    }
  }

  @include notDesktop {
    .body .imageBuffer {
      width: 150px;
      height: 20px;
    }

    .headerImageHolder {
      top: 0;
      right: 0;
      border-radius: 0;

      width: 100px;
      height: 120px;
    }

    &.withRightArea {
      display: block;

      .rightArea {
        border-left: none;
      }
    }
  }*/
}
