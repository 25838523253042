@import "colors";
@import "connecting";
@import "section";
@import "media";

a {
  text-decoration: none;
}

.alert {
  color: white;
  background: $pink;
  font-weight: 600;
  padding: 8px 15px;

  a {
    color: black !important;
  }
}

.InternalLink {
  text-decoration: none;
  color: $linkBlue;

  &.simple {
    &:after {
      display: none;
    }
  }

  /*
  @include desktop {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 0;
      background: $strongblue;
      transition: width 0.3s, background 0.5s;
    }

    &:hover,
    &:active {
      color: $paleblue;

      &:after {
        background: $paleblue;
        width: 100%;
      }
    }
  }

  &.redLink {
    color: $palered;
    font-weight: bold;

    @include desktop {
      &:hover,
      &:active {
        color: $strongred;

        &:after {
          background: $strongred;
        }
      }
    }
  }

  &.blackLink {
    color: $offblack;
    font-weight: bold;

    @include desktop {
      &:hover,
      &:active {
        color: #666;

        &:after {
          background: #999;
        }
      }
    }
  }

  &.whiteLink {
    color: white;
    font-weight: bold;

    @include desktop {
      &:hover,
      &:active {
        color: $offwhite;

        &:after {
          background: $offwhite;
        }
      }
    }
  }

  &.noFade {
    transition: none;

    &:after {
      transition: none;
    }
  }

  &.unstyled:after {
    display: none;
  }*/
}

.ConnectingLink {
  position: relative;
  color: $linkBlue;
  text-decoration: none;

  /*&:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 100%;
    background: $darkblue;
  }*/

  :visited {
    color: $visitedLinkBlue;
  }

  &.darker {
    color: $colorfulText;
  }

  &.lighter {
    color: #f2f2f2;
  }
}

.ContinuingEdTag {
  color: white;
  font-size: 22px;
  font-weight: 600;

  @include phone {
    font-size: 18px;
  }
}

.APAApproved {
  font-size: 14px;
  line-height: 17px;
  color: #888;
}
