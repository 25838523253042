@import "media";

.talkDateTime {
  position: absolute;
  top: 0;
  left: -55px;
  right: -55px;
  display: flex;
  justify-content: center;
}

.TalkDescription {
  .SpeakerSideSection {
    position: relative;
    padding-bottom: 15px;

    &.twoSpeakers {
      @media (max-width: 1595px) {
        .ourSpeaker {
          left: 0;
          right: 0;
          text-align: center;
        }

        .pic.smallPic {
          top: 22px;
          width: 45%;
          border-radius: 0 0 100px 100px;

          &.firstSpeaker {
            left: 10px;
          }
          &.secondSpeaker {
            right: 10px;
          }
        }

        .titleSection.twoSpeakers {
          padding: 220px 45px 20px 45px;
          justify-content: space-between;

          .name {
            margin-right: 0 !important;
          }
        }
      }

      @include tablet {
        margin-top: 40px;

        .titleSection.twoSpeakers {
          padding: 220px 0 20px;

          .name {
            width: 45%;
            text-align: center;
          }
        }
      }

      @include phone {
        .pic.smallPic {
          top: 0;
        }

        .ourSpeaker {
          padding: 0;
          text-align: center;
        }

        .titleSection.twoSpeakers {
          padding: 220px 0 20px;

          .name {
            width: 45%;
            text-align: center;
          }
        }
      }
    }

    .ourSpeaker {
      position: absolute;
      top: -30px;
      right: 190px;
      font-size: 30px;
      font-weight: 600;
      background: white;
      padding: 15px 30px;
      z-index: 2;

      @include phone {
        line-height: 36px;
        right: 0;
        left: 0;
        top: -80px;
        padding: 0 60% 15px 30px;
      }
    }
    .pic {
      position: absolute;
      top: -125px;
      right: 15px;
      width: 200px;
      height: 185px;
      display: flex;
      justify-content: center;
      border-radius: 60px;
      overflow: hidden;
      z-index: 2;
      display: flex;
      align-items: baseline;

      img {
        width: 100%;
      }

      &.smallPic {
        right: 60px;
        width: 150px;

        img {
          width: auto;
          height: 100%;
        }
      }

      &.perepletchikova {
        height: 190px;

        img {
          margin-top: -5px;
        }
      }

      &.ritschel {
        img {
          margin-top: -20px;
        }
      }

      &.firstSpeaker {
        right: 417px;
      }
      &.secondSpeaker {
        right: 50px;
      }
    }
    .titleSection {
      position: relative;
      margin-bottom: 20px;
      padding: 70px 15px 15px;
      background: black;
      color: white;

      .name {
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
      }
      .subtitle {
      }

      &.twoSpeakers {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;

        padding: 80px 35px 20px 15px;

        ~ .titleSection {
          padding: 20px 15px 15px;
          border-top: 20px solid white;
        }

        .name:first-child {
          margin-right: 205px;
        }
      }
    }
    .description {
      padding: 0 15px 15px;
      font-size: 15px;
    }

    .secondSpeakerSection {
      margin-top: 90px;
    }
  }

  .LearningObjectives {
    .title {
      font-size: 24px;
    }

    .explanation {
      margin: 10px 0 5px;
    }

    ul {
      list-style-type: disc;
      padding: 0;
      padding-left: 2rem;
      margin: 0 0 20px;

      .objective {
        display: list-item;
        margin-bottom: 5px;

        .index {
          margin-right: 8px;
        }
      }
    }
  }

  .LevelOfInstruction {
    padding-bottom: 50px;

    .title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .level {
      padding-left: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    .description {
      font-size: 14px;
      padding-left: 1rem;

      span {
        font-style: italic;
      }
    }
  }
}

@include notDesktop {
  .talkDateTime {
    left: -20px;
    right: -15px;
  }

  .TalkDescription {
    .SpeakerSideSection {
      margin-top: 80px;
    }
    .ButtonGroup.split {
      flex-direction: column-reverse;
      align-items: flex-end;

      .APAApproved {
        margin-top: 15px;
        max-width: 100% !important;
      }
    }
  }
}

@include phone {
  .TalkDescription {
    .SpeakerSideSection {
      margin-top: 140px;
    }
  }
}
