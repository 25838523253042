.Modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;

  .modalBody {
    position: fixed;
    width: 800px;
    max-width: 100%;
    min-height: 200px;
    max-height: 100%;
    margin-left: -400px;
    left: 50%;
    top: 170px;
    background: white;
    color: $colorfulText;
    padding-bottom: 15px;
    border-radius: 15px;

    .title {
      font-size: 32px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      line-height: 70px;
      color: white;
      background: $darkBlue;
      border-radius: 15px 15px 0 0;

      .closeX {
        height: 100;
        font-size: 20px;
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        padding: 0 28px;
        color: white;

        &:active,
        &:focus {
          color: $greyText;
        }
      }
    }

    .scrollArea {
      height: 100%;
      overflow-y: auto;
      padding: 30px;
    }
  }
}

@include phone {
  .Modal {
    .modalBody {
      left: 0;
      margin-left: 0;
    }
  }
}
